import React from "react"
import styled from "styled-components"
import { pxToRem } from "../utilities/converters"

const H1 = styled.h1`
  font-family: Montserrat;
  font-size: ${pxToRem(32)}rem;
  font-weight: 700;
  line-height: 1.5;
`

const P = styled.p`
  font-family: Montserrat;
  font-weight: 300;
  line-height: 1.5;
  font-size: ${pxToRem(18)}rem;
`

const Strong = styled(P)`
  font-weight: 700;
`

const Subtitle = styled(P)`
  font-size: ${pxToRem(14)}rem;
`

export { H1, P, Strong, Subtitle }
