import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { H1, P } from "../components/typography"
import { pxToRem } from "../utilities/converters"
import { VSpacer } from "../components/spacers"

import "../components/layout.css"

const Container = styled.div`
  background: #222;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 100vh;
`

const Title = styled(H1)`
  font-size: ${pxToRem(64)}rem;
  color: #FF9A9E;
`

export default () => (
  <Container>
    <SEO title="Coming soon" keywords={[]} />
    <Title>mp.</Title>
    <P>Coming soon.</P>
  </Container>
)
